const isUp = function () {
    const $window = $(window);
    const $document = $(document);
    const $elem = $('.js-up');
    const $elemFixInd = $('.js-up-fix-indicator');
    const scrollOffset = 250;
    const footerHeight = {
        desktop: 470,
        mobile: $('footer').outerHeight(true),
    };

    const construct = function () {
        initEvents();
    };

    const isMobile = function () {
        try {
            document.createEvent('TouchEvent');
            return true;
        } catch (e) {
            return false;
        }
    };

    const setFixed = function () {
        $elemFixInd.attr('data-fixed', 'true');
    };

    const setUnfixed = function () {
        $elemFixInd.attr('data-fixed', 'false');
    };

    const positionHandler = function () {
        if (isMobile()) footerHeight.mobile = $('footer').outerHeight(true);
        const footer = (isMobile()) ? footerHeight.mobile : footerHeight.desktop;
        if ($window.scrollTop() >= scrollOffset && $window.scrollTop() <= ($document.height() - $window.height() - footer)) {
            setFixed();
        } else {
            setUnfixed();
        }
    };

    const scrollToTop = function () {
        window.scrollTo(0, 0);
    };

    const initEvents = function () {
        $elem.on('click', scrollToTop);
        $window.on('scroll', positionHandler);
    };

    construct();
};

module.exports = isUp;
