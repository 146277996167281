import "trafimage-maps";
import React, { useRef, useEffect } from "react";
import TrafimageMapboxLayer from "trafimage-maps/es/layers/TrafimageMapboxLayer";
import { VectorLayer } from "mobility-toolbox-js/ol";
import OLVectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";
import { Icon, Style } from "ol/style";
const createTopic = () => {
    const featureCollection = JSON.parse(document.querySelector("#plf-trafimage-id-1").dataset.features);
    const format = new GeoJSON({
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857",
    });
    const infoWindow = document.querySelector(".js-map-info-window");
    const topic = {
        name: "Default",
        key: "default",
        elements: {
            mapControls: true,
            geolocationButton: false,
            menu: false,
            header: false,
            footer: false,
            permalink: false,
        },
        layers: [
            new TrafimageMapboxLayer({
                style: "base_bright_v2",
            }),
            new VectorLayer({
                olLayer: new OLVectorLayer({
                    style: [
                        new Style({
                            image: new Icon({
                                src: "/design/img/marker_red.png",
                                scale: 0.5,
                            }),
                        }),
                    ],
                    source: new VectorSource({
                        features: format.readFeatures(featureCollection),
                    }),
                }),
                //@ts-ignore
                onClick: ([feature]) => {
                    if (feature) {
                        infoWindow.innerHTML = feature.get("template").replaceAll("\\", "");
                        infoWindow.style.display = "block";
                        const reservationButton = infoWindow.querySelector(".plf-reservation-list-choose-stop");
                        reservationButton.addEventListener("click", function (e) {
                            e.preventDefault();
                            const id = reservationButton.dataset.id;
                            document.querySelector(`.plf-reservation-list a[data-id="${id}"]`).click();
                        });
                        const infoWindowClose = infoWindow.querySelector(".js-map-info-container-close");
                        infoWindowClose.addEventListener("click", () => {
                            infoWindow.style.display = "none";
                        });
                    }
                },
            }),
        ],
    };
    return topic;
};
const setAutomaticZoom = () => {
    const automaticZoomButton = document.querySelector('.wkp-fit-extent');
    automaticZoomButton === null || automaticZoomButton === void 0 ? void 0 : automaticZoomButton.click();
};
const SchulzugMap = () => {
    const ref = useRef();
    const topic = createTopic();
    useEffect(() => {
        const map = ref.current;
        if (!map) {
            return;
        }
        // @ts-ignore
        map.topics = [topic];
        setAutomaticZoom();
        return () => {
            // @ts-ignore
            map.topics = null;
        };
    }, []);
    return (React.createElement("trafimage-maps", { ref: ref, zoom: "8", apiKey: "5cc87b12d7c5370001c1d6552ba34a27b68047e0bfd72934d8a57897", embedded: "true" }));
};
export default SchulzugMap;
