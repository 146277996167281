import React from 'react';
import { render } from 'react-dom';
import { Footer } from '@sbb/ui';
document.addEventListener('DOMContentLoaded', () => {
    const footerWrapper = document.querySelector('.js-footer-wrapper');
    if (footerWrapper === null) {
        return;
    }
    const footerSections = JSON.parse(footerWrapper.dataset.footerSections);
    const footerBottomLinks = JSON.parse(footerWrapper.dataset.footerBottomLinks);
    function initToTop() {
        const toTopButton = document.querySelector('.o-footer__toTopButton');
        window.addEventListener('scroll', onScroll);
        toTopButton.addEventListener('click', () => window.scrollTo(0, 0));
        function onScroll() {
            if (window.scrollY <= 450) {
                toTopButton.style.display = 'none';
            }
            else {
                toTopButton.style.display = 'block';
            }
        }
        toTopButton.style.display = 'none';
    }
    function initCookieFooter() {
        const cookieLink = document.getElementsByClassName('ts-cookie-link')[0];
        if (!(cookieLink)) {
            return;
        }
        cookieLink.addEventListener('click', () => {
            window.Optanon.ToggleInfoDisplay();
        });
    }
    render(React.createElement(React.StrictMode, null,
        React.createElement(Footer, { fixed: false, title: footerWrapper.dataset.footerTitle, footerSections: footerSections, footerBottomLinks: footerBottomLinks, toTopButtonLabel: footerWrapper.dataset.toTopLabel })), footerWrapper);
    initCookieFooter();
    initToTop();
});
