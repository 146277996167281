const isSidebar = function () {
    const $trigger = $('.js-sidebar-trigger');
    const sidebarCls = '.js-sidebar';
    const sidebarOverlayCls = '.js-sidebar-overlay';
    const $sidebar = $(sidebarCls);
    const $sidebarOverlay = $(sidebarOverlayCls);

    const construct = function () {
        initEvents();
    };

    const isTouch = function () {
        try {
            document.createEvent('TouchEvent');
            return true;
        } catch (e) {
            return false;
        }
    };

    const setActive = function ($e) {
        $e.attr('data-open', 'true');
    };

    const setInactive = function ($e) {
        $e.attr('data-open', 'false');
    };

    const sidebarHandler = function (e) {
        e.stopImmediatePropagation();
        if ($sidebar.attr('data-open') === 'true') {
            setInactive($sidebar);
            setInactive($trigger);
            setInactive($sidebarOverlay);
        } else {
            setActive($sidebar);
            setActive($trigger);
            setActive($sidebarOverlay);
        }
    };

    const swipeHandler = function () {
        setInactive($sidebar);
        setInactive($trigger);
        setInactive($sidebarOverlay);
    };

    const initEvents = function () {
        $trigger.on('click', sidebarHandler);

        $sidebarOverlay.on('click', sidebarHandler);

        if (isTouch()) {
            $sidebarOverlay.on('swipeleft', swipeHandler);
            $sidebar.on('swipeleft', swipeHandler);
        }
    };

    construct();
};

module.exports = isSidebar;
