const isLangSelect = function () {
    const triggers = $('.js-langswitch a');
    const construct = function () {
        initClickHandler();
    };

    const initClickHandler = function () {
        triggers.click(function (event) {
            event.preventDefault();

            const rel = $(this).data('id');
            if (typeof rel === 'number') {
                /* eslint-disable */
                postToUrl(location.href, { sv_lang: rel, sv_lang_change: true });
                /* eslint-enable */
            }
        });
    };

    construct();
};

module.exports = isLangSelect;
