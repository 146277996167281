/* eslint-disable import/first */
/* eslint-disable import/order */
import '../scss/app.scss';
import useJqueryGlobal from './lib/jquery-global';
useJqueryGlobal();
import '../templates/components/header/header';
import '../templates/components/footer/footer';
import '../../../platform/widgets/view/reservation/templates/steps/chooseStop/map';
import IsSidebar from '../templates/components/sidebar/sidebar';
import IsUp from '../templates/components/footer/up/up';
import IsLangSelect from '../templates/components/footer/footer-bottom/langswitch/langswitch';
document.addEventListener('DOMContentLoaded', () => {
    IsSidebar();
    IsUp();
    IsLangSelect();
    const $document = $(document);
    window.refreshCantonState = function ($select) {
        if (typeof $select !== 'object') {
            $select = $('#form_rsvCountry');
            if (!$select.length) {
                return false;
            }
        }
        const $inputCanton = $('#form_rsvCanton');
        const $inputCantonContainer = $inputCanton.closest('div');
        // 0 === 'Schweiz'
        if (`${$select.val()}` === '0') {
            $inputCantonContainer.addClass('plf-form-required');
            $inputCantonContainer.show();
        }
        else {
            $inputCantonContainer.removeClass('plf-form-required');
            $inputCantonContainer.hide();
            $inputCantonContainer.val('');
        }
        return true;
    };
    // @ts-ignore
    refreshCantonState();
    $document.on('change', '#form_rsvCountry', function () {
        window.refreshCantonState($(this));
    });
});
