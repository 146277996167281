import React from 'react';
import { render } from 'react-dom';
import { Header } from '@sbb/ui';
document.addEventListener('DOMContentLoaded', () => {
    const headerWrapper = document.querySelector('.js-header-wrapper');
    if (headerWrapper === null) {
        return;
    }
    const isLoggedIn = headerWrapper.dataset.isLoggedIn;
    const metaNavLinks = JSON.parse(headerWrapper.dataset.metaNavLinks);
    const mainNavLinks = JSON.parse(headerWrapper.dataset.mainNavLinks);
    const languageConfig = JSON.parse(headerWrapper.dataset.languageConfig);
    const additionalActions = JSON.parse(headerWrapper.dataset.additionalActions);
    render(React.createElement(React.StrictMode, null,
        React.createElement(Header, { headerTriggerLabel: headerWrapper.dataset.headerTriggerLabel, usermenuActions: null, isLoggedIn: isLoggedIn === 'true', username: headerWrapper.dataset.username, headerTitle: headerWrapper.dataset.headerTitle, activeLangLabel: headerWrapper.dataset.activeLangLabel, loginUrl: headerWrapper.dataset.loginUrl, logoutUrl: headerWrapper.dataset.logoutUrl, loginLabel: headerWrapper.dataset.loginLabel, logoutLabel: headerWrapper.dataset.logoutLabel, metaNavLinks: metaNavLinks, mainNavTitle: headerWrapper.dataset.mainNavTitle, mainNavLinks: mainNavLinks, languageConfig: languageConfig, additionalActions: additionalActions, searchFormAction: null, searchPlaceholder: null, searchLabel: null })), headerWrapper);
});
